import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { GRID_POINTS, Icon, Promotion, useSimpleInterface } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import placeholder from '../../../../assets/img/image-placeholder.svg';
import { CheckedFilters } from '../CheckedFilters';
import { Filters } from './components/Filters';
import { StyledContentListItem, StyledContentWrap, StyledPromotionsWrap, StyledSideBar, StyledWrap } from './styled';

export const Promotions = inject('store')(
    observer(({ store, brandId }) => {
        const navigate = useNavigate();
        const {
            brandBestSellers,
            getPromotions,
            promotionsPending,
            promotions,
            setPrevProductId,
            prevProductId
        } = store.brandPageStore;
        const { setIsPageReloaded } = store.pdpStore;
        const simple = useSimpleInterface(GRID_POINTS.md);

        useEffect(() => {
            if (prevProductId) {
                const element = document.getElementById(prevProductId);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }

                setPrevProductId(null);
            }
        }, []);

        useEffect(() => {
            if (!promotionsPending && !(prevProductId && promotions.length)) getPromotions({ brandId });
        }, []);

        return (
            <StyledWrap $withTopPadding={brandBestSellers?.length} $simple={simple}>
                {promotionsPending ? <Icon.Loader position='fixed' /> : null}
                <StyledSideBar $simple={simple}>
                    <Filters simple={simple} />
                </StyledSideBar>
                <StyledContentWrap $simple={simple}>
                    <CheckedFilters simple={simple} />
                    <StyledPromotionsWrap>
                        {promotions
                            ? promotions.map((promotion) => (
                                  <StyledContentListItem key={promotion._id}>
                                      <Promotion
                                          placeholder={placeholder}
                                          onBuy={() => {
                                              setIsPageReloaded(false);
                                              store.trackingStore.trackClick({ promotion });

                                              navigate({
                                                  pathname: `/products/${promotion._id}`,
                                                  search: '?page_ref=brand-page'
                                              });
                                          }}
                                          promotion={promotion}
                                      />
                                  </StyledContentListItem>
                              ))
                            : null}
                    </StyledPromotionsWrap>
                </StyledContentWrap>
            </StyledWrap>
        );
    })
);
