import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    ArrowChevronForwardIcon,
    Button,
    CopyIcon,
    Icon,
    Promotion,
    roundToLocaleString,
    Typography
} from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import placeholder from '../../../../../../../assets/img/image-placeholder.svg';
import { app } from '../../../../../../../config';
import { ROUTE_PATHS } from '../../../../../../index';
import { getProductShareLink } from '../../../../../../utils';
import { useShareProductNotification } from './hooks';
import {
    StyledButton,
    StyledContent,
    StyledInfo,
    StyledPrice,
    StyledProductTitle,
    StyledTitle,
    StyledUserName,
    StyledWrapper
} from './styled';

const ShareProductModalContent = inject('store')(
    observer(({ onCloseModal, store, promotionId }) => {
        const navigate = useNavigate();
        const [copied, setCopied] = useState(false);
        const { isLoggedIn, isApprovedCustomer, data } = store.accountStore;
        const { closePDP } = store.pdpStore;
        const { pending, sharedPromotion, removeSharedPromotion, guestPointsUpTo } = store.shareProductStore;
        const { completeTask } = store.tasksStore;
        const taskId = store.tasksStore?.copyProductLinkTask?._id;
        const isTaskActive = isApprovedCustomer && store.tasksStore?.copyProductLinkTask?.data?.status === 'active';

        const { onTaskComplete } = useShareProductNotification(store);

        const onCopyLink = async () => {
            try {
                await navigator.clipboard.writeText(
                    isLoggedIn
                        ? getProductShareLink({
                              referrer: data._id,
                              promotion: sharedPromotion
                          })
                        : `${app.giftlyUrl}/marketplace/${sharedPromotion._id}`
                );

                store.trackingStore.trackClick({ promotion: sharedPromotion, customerReferrerId: data._id });
            } catch (e) {
                // do nothing
            } finally {
                setCopied(true);

                if (isTaskActive) {
                    completeTask({
                        taskPayload: { productId: promotionId },
                        taskId,
                        taskCompleted: isTaskActive,
                        onAfterSuccess: onTaskComplete
                    });
                }
            }
        };

        useEffect(() => {
            return () => removeSharedPromotion();
        }, []);

        return pending ? (
            <Icon.Loader />
        ) : (
            <Fragment>
                {sharedPromotion ? (
                    <StyledWrapper>
                        {isLoggedIn && (
                            <StyledTitle type='body1'>
                                Receive up to <span>{guestPointsUpTo} pts</span> whenever a guest buys this product
                                using your product link (conditions apply)
                            </StyledTitle>
                        )}
                        <StyledContent>
                            <Promotion.Img
                                placeholder={placeholder}
                                height='80px'
                                width='80px'
                                images={[
                                    {
                                        src: sharedPromotion.images[0].src,
                                        position: 1
                                    }
                                ]}
                            />
                            <StyledInfo>
                                <StyledUserName type='body2'>{sharedPromotion.brandName}</StyledUserName>
                                <StyledProductTitle level={3}>{sharedPromotion.title}</StyledProductTitle>
                                <StyledPrice type='badgeSmall'>
                                    $
                                    {roundToLocaleString(
                                        Math.min(...sharedPromotion.variants.map(({ price }) => price))
                                    )}
                                </StyledPrice>
                            </StyledInfo>
                        </StyledContent>
                        <StyledButton
                            id='sp_copy_link'
                            size='large'
                            type='primary'
                            onClick={onCopyLink}
                            $copied={copied}
                        >
                            <Icon color={copied ? 'success' : 'primaryText'} component={() => <CopyIcon />} />
                            <Typography.Text as='span' type='badgeLarge' color={copied ? 'success' : 'primaryText'}>
                                {copied ? 'Link copied to clipboard!' : 'Copy link'}
                            </Typography.Text>
                        </StyledButton>
                        {isLoggedIn && (
                            <Button
                                size='large'
                                type='text'
                                onClick={() => {
                                    onCloseModal();
                                    closePDP();
                                    return navigate(
                                        isLoggedIn ? ROUTE_PATHS.inviteAndEarnRules : ROUTE_PATHS.onboarding
                                    );
                                }}
                            >
                                <span>{isLoggedIn ? 'Learn more' : 'Apply for access'}</span>
                                <Icon component={() => <ArrowChevronForwardIcon />} />
                            </Button>
                        )}
                    </StyledWrapper>
                ) : null}
            </Fragment>
        );
    })
);

export default ShareProductModalContent;
