import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSimpleInterface } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { ROUTE_PATHS } from '../../../../../../../../shared';
import { GRID_POINTS } from '../../../../../../../../utils';
import { SuiteCoupon } from '../../../SuiteCoupon';
import StrategyBanner from './components/StrategyBanner';
import { Styles } from './styles';

export const SuiteOpenedList = inject('store')(
    observer(({ store }) => {
        const navigate = useNavigate();
        const simple = useSimpleInterface(GRID_POINTS.md);
        const { data, setIsPageReloaded } = store.suitePromotionsStore;

        const onGSCouponClick = (e, coupon) => {
            e.preventDefault();
            e.stopPropagation();
            store.trackingStore.trackClick({ promotion: coupon.promotion });
            setIsPageReloaded(false);

            navigate({
                pathname: ROUTE_PATHS.suiteGsCouponDetails,
                search: `couponId=${coupon._id}`
            });
        };

        return (
            <Styles>
                {Boolean(data && data.length) &&
                    data.map((promotionItem) => (
                        <SuiteCoupon
                            shippingAddresses={store.accountStore.shippingAddresses}
                            onClick={(e) => onGSCouponClick(e, promotionItem)}
                            simple={simple}
                            data={promotionItem}
                            id={promotionItem._id}
                            key={promotionItem._id}
                        />
                    ))}
                <StrategyBanner />
            </Styles>
        );
    })
);
